<template>
  <div class="judge-container">
    <div class="header">
      <div class="header-left">{{typeName}}</div>
      <div class="header-right">
        <div class="box" @click="open">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20" fill="none"><g opacity="1" transform="translate(0 0)  rotate(0 10 10)"><path id="矩形 15" fill-rule="evenodd" style="fill:#FFFFFF" transform="translate(0 0)  rotate(0 10 10)" opacity="1" d="M5,20L15,20C17.76,20 20,17.76 20,15L20,5C20,2.24 17.76,0 15,0L5,0C2.24,0 0,2.24 0,5L0,15C0,17.76 2.24,20 5,20Z " /><rect  id="矩形 15" style="stroke:#707070; stroke-width:1; stroke-opacity:1; stroke-dasharray:0 0" transform="translate(0 0)  rotate(0 10 10)" x="0.5" y="0.5" rx="4.5"  width="19" height="19" /><g opacity="1" transform="translate(5 6)  rotate(0 5 4)"><path  id="直线 4" style="stroke:#707070; stroke-width:2; stroke-opacity:1; stroke-dasharray:2 2" transform="translate(0 0)  rotate(0 5 0)" d="M10,0Z M0,0L10,0 " /><path  id="直线 5" style="stroke:#707070; stroke-width:2; stroke-opacity:1; stroke-dasharray:2 2" transform="translate(0 4)  rotate(0 5 0)" d="M10,0Z M0,0L10,0 " /><path  id="直线 6" style="stroke:#707070; stroke-width:2; stroke-opacity:1; stroke-dasharray:2 2" transform="translate(0 8)  rotate(0 5 0)" d="M10,0Z M0,0L10,0 " /></g></g></svg>
        </div>
      </div>
    </div>
    <div class="main">
        <div  style="width:100%;height:100%;" >
        <transition :name="transitionName">
          <div class="topic-item" v-if="currentTopic?.content">
              <div v-if="currentTopic.content" class="topic-truck">
                <div style="display:flex;align-items: baseline;">
                  <span>{{currentTopic.sort}}.</span>
                  <span v-if="currentTopic.content.body" v-html="currentTopic.content.body"></span>
                </div>
                <div v-if="currentTopic.nodeType === 'groupQuestion'" style="display:flex;flex-direction:column">
                  <div v-for="(c, v) in currentTopic.children" :key="c.nodeId" style="display:flex; flex-direction:column;">
                    <div style="display:flex;align-items: baseline;">
                      <span>{{v+1}}、</span>
                      <span v-html="c.nodeName"></span>
                    </div>

                    <!-- 填空题小问不显示 -->
                    <div v-if="c.children && c.children.length && c.questionType!== 'blank'" style="padding-left:15px;">
                      <div v-for="child in c.children" :key="child.nodeId" style="display:flex;align-items: baseline;">
                        <span>{{v+1}}.{{child.sort}}</span>
                        <span v-html="child.nodeName"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="choice-options-wrap" v-if="choiceStructureId.includes(currentTopic.content.structureId)">
                <div class="choice-option" v-for="ele in currentTopic.content.choices" :key="ele.letter" @click="clickOption(ele)">
                  <span class="letter"
                    :class="{
                    'right-answer' : ele.answerCheck === true,
                    'error-answer' : ele.answerCheck === false
                    }"
                    
                  >{{ele.letter}}</span>
                  <span class="content" v-html="ele.option"></span>
                </div>
              </div>

              <div class="answer-wrap">
                <div class="answer-item" v-if="!choiceStructureId.includes(currentTopic.content.structureId)">
                  <div class="answer-item-title">你的答案</div>
                     <div class="student-answer" v-for="(s, d) in currentTopic.content.answer" :key="'student' + d">
                    <span>{{d+1}}、</span>
                    <div style="display:flex;align-items:center;">
                      <span class="answer-btn" :class="{'right-btn': s.answerCheck === '1'}" @click="handleUserAnswer(s,d, 1)">✔</span>
                      <span class="answer-btn" :class="{'error-btn': s.answerCheck === '0'}" @click="handleUserAnswer(s,d, 0)">×</span>
                    </div>
                  </div>
                </div>
                <div class="answer-item">
                  <div class="answer-item-title">正确答案：</div>

                    <div v-for="(a, i) in currentTopic.content.answer" :key="i" class="answer-item">
                    <span>{{i + 1}}、</span>
                    <span v-html="a.answer"></span>
                  </div>
                </div>
              </div>
          </div>
        </transition>
        </div>
    </div>
    <div class="footer">
      <div class="btn-wrap">
        <div class="btn-item page-btn" :class="{'btn-disabled': current === 0}" @click="prev">上一题</div>
        <div class="btn-item">
          <span>{{current + 1}}</span>
          <span>/</span>
          <span>{{topics.length}}</span>
        </div>
        <div class="btn-item page-btn" :class="{'btn-disabled': topics.length === current + 1}" @click="next">下一题</div>
      </div>
    </div>

    <Actionsheet ref="Actionsheet">
      <div class="section" v-for="item in list" :key="item.nodeId">
        <div class="section-title">{{item.typeName}}</div>
        <div class="section-content">
          <div v-for="(ele, index) in item.children" :key="ele.nodeId" class="circle" :class="{'right-btn':ele.answerCheck === true, 'error-btn': ele.answerCheck === false}" @click="handleClick(ele, index)">
            {{ele.sort}}
          </div>
        </div>
      </div>

      <div class="actionsheet-btn-wrap">
        <div class="cancel-btn actionsheet-btn" @click="hide">取消</div>
        <div class="submit-btn actionsheet-btn" @click="submit">提交试卷</div>
      </div>
    </Actionsheet>


    <transition name="fade">
      <div v-if="loading" class="loading">
        {{loadingText}}

        <div class="close-btn" v-if="showColse" @click="handleClose">×</div>
      </div>
    </transition>
  </div>
</template>

<script>
import Actionsheet from '../../components/Actionsheet'
import request from "../../request";
import wx from 'weixin-js-sdk'

export default {
    name: 'Judge',
    components: { Actionsheet},
    data() {
        return {
          id: '',
          studentId: '',
          taskId: '',
          token: '',
          current: 0,
          currentTopic: {},
          paper: {},
          topics: [],
          list: [],
          transitionName: '',
          choiceStructureId: [2, 3, 4],
          loading: true,
          loadingText: '',
          showColse: false
        };
    },
    computed: {
      typeName() {
        return this.topics.length && this.topics[this.current].typeName
      },
      // currentTopic() {
      //   return this.topics[this.current]
      // },
    },
    watch: {
      current:{
        handler(n) {
          // console.log('n', n)
          this.currentTopic = this.topics[n] || {}
          // console.log('this.currentTopic', this.currentTopic)
          
          // 处理可能出现子题无答案的情况
          this.currentTopic.content.answer = this.currentTopic.children && this.currentTopic.content.answer.length !== this.currentTopic.children.length && this.currentTopic.children.length ? this.currentTopic.children.reduce((a, c) => {
              const answer = c.content?.answer.map((item, i) => {
                return {
                  answer: typeof item === 'string' ? item : item.answer,
                  nodeId: typeof item === 'string' ? c.children[i]?.nodeId : item.nodeId || ''
                }
              })

              // (c.content?.answer || []  ).map(item => )
              const temp = c.content?.answer.length ? answer : [{ answer: '', nodeId: c.nodeId}]
              return [...a, ...temp]
            }, []) : this.currentTopic.content.answer


      
          // console.log('currentTopic===>', this.currentTopic)
        },
      },
      currentTopic: {
        handler() {
          this.math()
        },
        deep: true
      },
      topics: {
        handler(n) {
          this.list = this.classify(n, 'typeId')
          this.currentTopic = n[this.current]

          console.log('this.currentTopic', this.currentTopic)
        },
        deep: true
      }
    },
    created() {
      document.title = '自阅'
      this.setParams()
      this.loadMathJax()
    },
    mounted() {

    },
    methods: {
      setParams() {
        const params = this.$route.query
        this.id = params.id
        this.token = params.token
        this.taskId = params.taskId
        this.studentId = params.studentId
      },

      loadMathJax() {
        window.MathJax = {
          startup: {
            pageReady: () => {
              return window.MathJax.startup.defaultPageReady().then(() => {
                if (this.id) {
                  this.getData()
                }
              })
            }
          },
          tex: {
            inlineMath: [['$', '$'], ['\\(', '\\)'], ['\\[', '\\]']],
            displayMath: [['$$', '$$']]
          }
        }
        this.loadScript()
      },

      loadScript() {
        const script = document.createElement('script')
        const head = document.getElementsByTagName('head')[0]
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://pen.zwres.com/static-assets/mathJax/es5/tex-svg.js'
        head.appendChild(script)
      },

      getData() {
        this.loadingText = '正在加载...'
        this.loading = true
        request({
          url: '/api/paper/v2/' + this.id,
          method: 'get',
          headers: {
            'Authorization': 'Bearer ' + this.token
          }
        }).then(response => {
          this.loading = false
          
          // this.topic = this.treeToList(response.data)
          // this.math()
          this.paper = response.data
          
          this.setTopic(this.paper)
          this.list = this.classify(this.topics, 'typeId')
          this.math()
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
      },

      setTopic(paper) {
        if(!paper || !paper.children) return

        const loop = (node) => {
          console.log('node===>', node)
          let arr = []
          const type = node.nodeType
          node.children.forEach(item => {
            if(item.nodeType === 'bigQuestion') {
              arr = [...arr, ...loop(item)]
            } else {
              if(item.nodeType === 'question' || item.nodeType === 'groupQuestion') {
               if(type === 'bigQuestion') {
                  item.parentId = node.nodeId
                  item.parentNodeType = type
               }
              }
              arr = [...arr, item]
            }
            // if(c.nodeType === 'question' || c.nodeType === 'groupQuestion') {

            //   // if(c.nodeType === 'groupQuestion') {
            //   //   // 题组收集子题的答案
            //   //   c.content.answer = loopAnswer(c)
            //   // }

            //   if(c.nodeType === 'question') {
            //      type === 'bigQuestion' && (c.parentId = node.nodeId)
            //      c.content.answer = c.content.answer.map(item => { return { answer: item, nodeId: c.nodeId }})
            //   }
            //   a = [...a, c]
            // } else {
            //   c.children && loop(c)
            // }
          })
          return arr
        }

        const loopAnswer = (node) => {
          if((node.nodeType === 'question' || node.nodeType === 'childQuestion') && node.children && node.children.length) {
            const answer = node.children.map((t, i) => { return {
              answer: node.content.answer[i] || '',
              nodeId: t.nodeId
            }})

            return answer
          }

          return node.children.reduce((a, c) => {
            if(c.nodeType === 'question' || c.nodeType === 'childQuestion' || c.nodeType === 'childGroupQuestion' || c.nodeType === 'groupQuestion') {
              
              if(c.children && c.children.length) {
                console.log("c=======>", c)
                if(c.nodeType === 'childQuestion' || c.nodeType === 'smallQuestion') {
                  const answers = c.children.map((item, i) => {   
                    let index = c.content.answer.findIndex(ele => ele.nodeId === item.nodeId)

                    let answer = index >= 0 ? c.content.answer[index]?.answer || '' : c.content.answer[i] || ''
                    return {
                    answer,
                    nodeId: item.nodeId
                  }})
                  a = [...a, ...answers]
                  // console.log('a====>', a)
                } else {
                  a = [...a, ...loopAnswer(c)]
                }
              } else {
                c.content.answer = (c.content.answer || []).map(item => { return { answer: item, nodeId: c.nodeId }})
                a = [...a, ...c.content.answer]
              }
            }

            return a
          }, [])
        }

        this.topics = []

        this.topics = loop(this.paper)

        // console.log("topics-before", this.topics)

        this.topics.forEach(item => {
          // console.log('item===>', item)
          if(item.nodeType === 'groupQuestion' || (item.nodeType === 'question' && item.children && item.children.length)) {
            // console.log('item', item, loopAnswer(item))
            item.content.answer = loopAnswer(item)

          } else {
            item.content.answer = (item.content?.answer || []).map((ele, i) => { 
              // console.log('ele===>', ele)
              if(item.children && item.children.length) {
                return { answer: ele, nodeId:  item.children[i].nodeId }
              } else {
                return { answer: ele, nodeId: item.nodeId }
              }
            })

            // console.log('item.content.answer2===>', item.content.answer)
          }
        })
        this.topics = JSON.parse(JSON.stringify(this.topics))
      },

      /**
       * @description: 讲数组进行分类
       * @param {*} arr 数据源
       * @param {*} key 数组根据值进行分类
       * @return {*}
       */
      classify(arr, key) {
        if (!(arr instanceof Array)) return '请传入正确格式的数组'
        if (!key) return '请传入对象属性'

        const keyArr = [...new Set(arr.map(item => item[key]))]

        const result = []
        keyArr.forEach(item => {
          const temp = arr.filter(ele => ele[key] === item)
          result.push(temp)
        })
        const newArr = result.map(item => {
          return {
            typeId: item[0].typeId,
            typeName: item[0].typeName,
            children: item
          }
        })
        // console.log('newArr', newArr)
        return newArr
      },

      // 选择题点击选项
      clickOption(option) {
        let studentAnswer = this.currentTopic.content.studentAnswer || []
        
        if(studentAnswer.includes(option.letter)) return
        studentAnswer.push(option.letter)

        

        // 单选只保留一个，多选和不定项保留多个
        if(this.currentTopic.typeId === 1 && studentAnswer.length > 1) {
          studentAnswer= studentAnswer.splice(studentAnswer.length - 1)
        }
        this.updateAnswer(this.currentTopic.nodeId, studentAnswer)
      },

      // 更新列表中的答案
      updateAnswer(id, answer) {
        const index = this.topics.findIndex(item => item.nodeId === id)
        if(index >= 0) {
          this.topics[index]
        }
        const rightAnswer = this.topics[index].content.answer.map(item => item.answer)
        const answerCheck = answer.join(',') === rightAnswer.join(',')
        this.topics[index].content.studentAnswer = answer
        // 整个选择题的答案是否正确
        this.topics[index].answerCheck = answerCheck
        // 选择题显示学生选择的选项正确还是错误
        this.currentTopic.content.choices.forEach(item => {
          if(answer.includes(item.letter)) {
            item.answerCheck = rightAnswer.includes(item.letter)
          } else {
            item.answerCheck = ''
          }
        })
        this.$set(this.currentTopic, 'content', {...this.currentTopic.content, studentAnswer:answer, answerCheck})
        // console.log('topic', this.topics)
      },
      
      math() {
        this.$nextTick(() => {
          if (window.MathJax) {
            window.MathJax.typeset()
          }
        })
      },

      open() {
        this.$refs.Actionsheet.open()
      },

      hide() {
        this.$refs.Actionsheet.open()
      },

      prev() {
        if(this.current === 0) return
        this.current--
        this.transitionName = 'slide-right'
      },

      next() {
        if(this.current+1 === this.topics.length) return
        this.current++
        this.transitionName = 'slide-left'
      },

      // 点击题号切换
      handleClick(ele) {
        const i = this.topics.findIndex(item => item.nodeId === ele.nodeId)
        // console.log('i', i)
        if(i + 1 == 0) return

        this.transitionName = this.current < i + 1 ? 'slide-right' : 'slide-left'
        this.current = i
        this.hide()
      },

      handleUserAnswer(node, i,  val) {
        console.log('node', node)
        // console.log('this.currentTopic', this.currentTopic)
        this.topics.forEach(item => {
          if(item.nodeType === 'question') {
            // 非题组 （分选择题和填空题）
            if(item.questionType === 'choice') {
              // 选择题
              // 学生答题答案
              const studentAnswer = item.content?.studentAnswer || []
              // 正确答案
              const rightAnswer = item.content.answer.map(t => t.answer)
              // 选择题答题情况
              item.answerCheck = studentAnswer.length ? studentAnswer.join(',') === rightAnswer.join(',') : ''
            } else {
              item.content.answer.forEach(v => {
              if(v.nodeId === node.nodeId) {
                  v.answerCheck = val + ''
                }
              })

            const checkLen = item.content.answer.filter( re => re.answerCheck && re.answerCheck !== '').length
            const totalLen = item.content.answer.length
            item.answerCheck = checkLen === totalLen ? item.content.answer.every( res => res.answerCheck === '1') : ''
            }
          } else {
            console.log('item', item)
            // 题组
            item.content.answer.forEach(v => {
              if(v.nodeId === node.nodeId) {
                v.answerCheck = val + ''
              }
            })

            const checkLen = item.content.answer.filter( re => re.answerCheck && re.answerCheck !== '').length
            const totalLen = item.content.answer.length
            item.answerCheck = checkLen === totalLen ? item.content.answer.every( res => res.answerCheck === '1') : ''

            // item.children.forEach( ele => {
              
            //   if(ele.children) {
            //     console.log('ele', ele)
            //     // 还有小问
            //     console.log('answer', ele.content.answer)
            //     ele.content.answer.forEach( v => {
            //       if(v.nodeId === node.nodeId) {
            //        v.answerCheck = val + ''
            //       }
            //     })


            //     // 小题答题情况，true-全对，false-只要存在错题，''-未答题
            //     const childAnswerCheckLen = ele.content.answer.filter( t => t.answerCheck && t.answerCheck !== '').length
            //     const childAnswerLen = ele.content.answer.length
            //     console.log('boolean', ele.content.answer.every(o => o.answerCheck === '1'))
            //     ele.answerCheck = childAnswerCheckLen === childAnswerLen ? ele.content.answer.every(o => o.answerCheck === '1') : ''
            //   } else {
            //     // 无小问，已是最末节点
            //     if(ele.nodeId === node.nodeId) {
            //       ele.answerCheck = val + ''
            //     }
            //   }
            // })
            // const checkLen = item.children.filter( re => re.answerCheck && re.answerCheck !== '').length
            // const totalLen = item.children.length
            // item.answerCheck = checkLen === totalLen ? item.children.every( res => res.answerCheck === '1') : ''
          }
        })

        this.list = this.classify(this.topics, 'typeId')
        this.currentTopic = this.topics[this.current]
        // console.log('this.list===>', this.list)
        // console.log('this.currentTopic', this.currentTopic)
      },

      // 提交
      submit() {
        console.log('this.topics', this.topics)
        const arr = this.topics.reduce((a, c)=> {
          let temp = []
          if(c.nodeType === 'question') {
            if(c.children) {
              temp = c.children.map((item, index) => {
               
                return {
                  ...item,
                  questionId: item.nodeId,
                  smallitemId: c.nodeId,
                  bigitemId: c.parentId,
                  answerCheck: c.content.answer[index].answerCheck
                }
              })
            } else {
              let userAnswer = ''
              let obj = {
                ...c,
                questionId: 0,
                smallitemId: c.nodeId,
                bigitemId: c.parentId,
                answerCheck: c.content.answer[0].answerCheck
              }
              if(c.questionType === 'choice') {
                userAnswer = (c?.content?.studentAnswer || []).join(',')
                obj.userAnswer = userAnswer
                obj.answerCheck = c.content.answerCheck
              }
              temp = [{...obj}]
            }
            
          }

          if(c.nodeType === 'groupQuestion' && c.children) {

            temp = c.children.reduce((p, v) => {
              if(v.nodeType === 'childGroupQuestion') {
                const o = v.children.reduce((k, l) => {
                
                  if(l.children) {
                    // 有smallQuestion
                    const arr = l.children.map((y, i) => {
                      return {
                        ...y,
                        questionId: y.nodeId,
                        smallitemId: l.nodeId,
                        bigitemId: v.nodeId,
                        answerCheck: y.content.answer[i].answerCheck
                      }
                    })
                    return [...k, ...arr]
                  } else {
                   
                    return [...k, {
                      ...l,
                      questionId: 0,
                      smallitemId: l.nodeId,
                      bigitemId: v.nodeId,
                       answerCheck: l.content.answer[0].answerCheck
                    }]
                  }
                }, [])
                // console.log('o====>', o)
                return [...p, ...o]
              } else {
                // 子题 childQuestion
                if(v.children && v.children.length) {
                  const tem = v.children.reduce((a, c) => [...a, c] , [])

                  // 小问
                  const ar = tem.reduce((aa, cc) => {
                    const ii = c.content.answer.findIndex(nn => nn.nodeId === cc.nodeId)

                    return [...aa, {
                      questionId: cc.nodeId,
                      smallitemId: v.nodeId,
                      bigitemId: v.parentId,
                      answerCheck: c.content.answer[ii].answerCheck
                    }]
                  }, [])

                  return [...p, ...ar]
                  // const a = v.children.map((item, u) =>{
                  //   console.log('item===>', item)
                  //   if(item.children) {
                  //     console.log('item.children===>', item.children)
                  //     const h = item.children.reduce((d, f) => {
                  //       return [...a, ...{
                  //         ...c,
                  //         questionId: f.nodeId,
                  //         smallitemId: item.nodeId,
                  //         bigitemId: c.nodeId,
                  //         answerCheck: v.content.answer[u].answerCheck || ''
                  //       }]
                  //     }, [])
                  //     console.log('h', h)
                  //   } else {
                  //     return {
                  //       ...item,
                  //       questionId: item.nodeId,
                  //       smallitemId: v.nodeId,
                  //       bigitemId: c.nodeId,
                  //       answerCheck: v.content.answer[u].answerCheck || ''
                  //     }
                  //   }
                      
                  //   // console.log('item====?v', v)
                  //   // const nodeId = item.nodeId
                  //   // const b = v.content.answer.findIndex(m => m.nodeId === node.nodeId)
                  // })
                  // return [...p, ...a]
                } else {
                  return [...p, {
                    ...v,
                    questionId: 0,
                    smallitemId: v.nodeId,
                    bigitemId: c.nodeId,
                    answerCheck: v.content.answer[0]?.answerCheck || ''
                  }]
                }
              }
        
            }, [])
            // console.log('temp', temp)
          }

          return [...a, ...temp]
        }, []).map(item => { return {bigitemId: item.bigitemId, smallitemId: item.smallitemId, questionId: item.questionId, userAnswer: item.userAnswer, answerCheck:item.answerCheck}})

        
        arr.forEach(item => {
          if(item.answerCheck === true ||  item.answerCheck === '1') {
            item.answerCheck = '1'
          } else if(item.answerCheck === false || item.answerCheck === '0') {
            item.answerCheck = '0'
          } else {
            item.answerCheck = ''
          }

          !item.userAnswer && delete item.userAnswer
          
          // item.userAnswer = item.userAnswer || ''
        })

        // console.log('arr', arr)
        request({
          url: `/api/student/bs/task/${this.studentId}/${this.taskId}/bs-student-result`,
          method: 'put',
          headers: {
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
          },
          data: arr
        }).then(result => {
          console.log('result', result)
          if(result.success) {
            // console.log('批阅成功')
            this.loading = true
            this.loadingText = '批阅成功'

            setTimeout(() => {
              this.backToMini()
              this.loading = false
              this.loadingText = ''
              this.$refs.Actionsheet.show = false
            }, 800)

          }
        }).catch(error => {
          // console.log('error', error)
          const result = JSON.parse(error.response.request.response)
          // console.log('result', result)
          if(!result.success) {
            this.loadingText = result.error.message
            this.loading = true
            this.showColse = true
            this.$refs.Actionsheet.show = false
          }
        })
      },

      // 扁平化数据
      flatData(data) {
        if(!data || !data.length || !(data instanceof Array)) return []
        const temp = []
        const loop = (value, parent = null) => {
            value.forEach(item => {
              if(item.children && item.children.length) {
                loop(item.children, item)
              } else {
                const parentNodeType = item.parentNodeType || ''
                
                let obj = {}
                if(parentNodeType === 'bigQuestion' || parentNodeType === 'childgroupQuestion') {
                  // 当前节点nodeType为question时 作为末端节点
                  obj = {
                    questionId: 0,
                    smallitemId: item.nodeId,
                    bigitemId: item.parentId,
                  }
                } else {
                  // 当前节点nodeType为smallquestion时 作为末端节点
                  obj = {
                    questionId: item.nodeId,
                    smallitemId: item.parentId,
                    bigitemId: parent.parentId,
                  }
                }
                temp.push(obj)
              }
            })
        }
        loop(data)
        return temp
      },

      // 返回小程序
      backToMini() {
        // 判断当前环境是否为小程序
        const ua = navigator.userAgent.toLowerCase()
        // console.log('ua', ua)
        // console.log('boolean', /MicroMessenger/i.test(ua))
        if (/MicroMessenger/i.test(ua)) {
          wx.miniProgram.getEnv((res) => {
            // console.log('res', res)
            if (res.miniprogram) {
              console.log('在小程序内')
              wx.miniProgram.navigateBack({ delta: 1 })
            }
          })
        }
      },

      handleClose() {
        this.loading = false
        this.loadingText = ''
        this.showColse = false
      }
    }
};
</script>

<style lang="scss" scoped>
.judge-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: 45px;
    padding: 0 25px;
    border-bottom: 1px solid rgba(230, 230, 230, 1); 
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .box {
      width: 20px;
      height: 20px;
      // border-radius: 5px;
      // background: rgba(255, 255, 255, 1);
    }
  }

  .main {
    position: relative;
    height: calc(100vh - 45px - 60px);
    overflow: hidden;

    .topic-item {
      width: 100%;
      height: calc(100%); //动态计算长度值
      overflow-y:scroll;
      // overflow-x: hidden;
      padding: 20px 30px;
      box-sizing: border-box;

      .topic-truck {
        font-size: 16px;
        color: #656565;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;

        ::v-deep * {
          padding: 0;
          margin: 0;
          line-height: 1.5;
        }
      }

      .choice-options-wrap {
        margin-bottom: 40px;

        .choice-option {
          margin-bottom: 25px;
          display: flex;
          line-height: 34px;

          .letter {
            flex: 0 0 34px;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            box-sizing: border-box;
            border: 1px solid;
            border-color: #A4A4A4;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            color: #A8A8A8;
          }

          .content {
            font-size: 16px;
            color: #656565;
          }

          .right-answer {
            background: #4690FF;
            border-color: #4690FF;
            color: #fff;
          }

          .error-answer {
            background: #FF7575;
            border-color: #FF7575;
            color: #fff;
          }
        }
      }

      .answer-wrap {
        .answer-item + .answer-item {
          margin-top: 20px;
        }

        .answer-item-title {
          margin-bottom: 10px;
        }

        .answer-btn {
          width: 34px;
          height: 34px;
          font-size: 20px;
          border-radius: 50%;
          border: 1px solid;
          border-color: #A6A6A6;
          color: #A6A6A6;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .answer-btn +.answer-btn  {
          margin-left: 20px;
        }

        

        .student-answer {
          display: flex;
          align-items: center;
        }

        .student-answer + .student-answer {
          margin-top: 15px;
        }
      }
    }


    .slide-right-enter-active,
    .slide-right-leave-active,
    .slide-left-enter-active,
    .slide-left-leave-active {
      will-change: transform;
      transition: all 0.3s;
      width: 100vw;
      height: 100%;
      position: absolute;
    }
    .slide-right-enter {
      opacity: 0;
      transform: translateX(-100%);
    }
    .slide-right-leave-active {
      opacity: 0;
      transform: translateX(100%);
    }
    .slide-left-enter {
      opacity: 0;
      transform: translateX(100%);
    }
    .slide-left-leave-active {
      opacity: 0;
      transform: translateX(-100%);
    }
  }

  .footer {
    width: 100%;
    height: 60px;

    .btn-wrap {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0 25px;
      font-size: 14px;

      .btn-item {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .page-btn {
        width: 70px;
        height: 100%;
        border-radius: 20px;
        background: #4690FF;
        border: 1px solid;
        border-color: #4690FF;      
        box-sizing: border-box;  
        color: #fff;    
      }

      .btn-disabled {
        background-color: #fff;
        border-color: #C2C2C2;
        color: #858585;
      }
    }
  }

  .right-btn {
    background-color: #4690FF;
    border-color: #4690FF !important;
    color: #fff !important;
  }

  .error-btn {
    background-color: #FF7575;
    border-color: #FF7575 !important;
    color: #fff !important;
  }

  .section-title {
    position: relative;
    padding: 12px 0 12px 10px;
    font-size: 14px;
    color: #B1B1B1;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 11px;
      background-color: #4690FF;
    }
  }

  .section-content {
    display: flex;
    flex-wrap: wrap;
  }

  .circle {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 1px solid;
    border-color: #959595;
    color: #959595;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:20px;
    margin-bottom: 15px;
  }

  .actionsheet-btn-wrap {
    margin-top: 100px;
    padding-bottom: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .actionsheet-btn {
      width: 100px;
      height: 30px;
      font-size: 14px;
      border-radius: 20px;
      background: #4690FF;
      border: 1px solid #4690FF;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }

    .actionsheet-btn + .actionsheet-btn {
      margin-left: 20px;
    }
  }

  .loading {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .close-btn {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #333;
    }
  }
}

::v-deep mjx-container[jax="SVG"][display="true"] {
  display: inline-block;
}
</style>
