<template>
  <div>
    <div class="mask" v-show="show" @click="show= !show"></div>
    <transition name="fade">
      <div class="actionsheet-wrap" v-show="show">
          <div class="actionsheet">
            <slot></slot>
          </div>
      </div>
      </transition>
  </div>
</template>

<script>
export default {
    name: 'Actionsheet',
    data() {
        return {
          show: false
        };
    },
    created() {

    },
    mounted() {

    },
    methods: {
      open() {
        this.show = !this.show
      }
    }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all .4s ease;
}

.fade-enter,
.fade-leave-to {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}

.mask {
  background: #000000ff;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.actionsheet-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  

  .actionsheet {
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-height: 100px;
    padding: 36px 28px 0 28px;
    box-sizing: border-box;
  }
}
</style>
